import styled, {css, device, themeGet } from "@styled";
import blueWaveGradientBg from "@data/images/bg/blueWaveGradientBg.png";
import benefitLayoutOneBg from "@data/images/bg/benefitLayoutOneBg.jpg";
export const TrQualityCriteriaWrapper = styled.section`
  padding: 150px 0 60px;
  background: url(${blueWaveGradientBg});
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  ${(props) =>
    props.layout === 1 &&
    css`
      padding:60px 0;
      background: url(${benefitLayoutOneBg});
      background-position: center right;
      background-size: cover;
      background-repeat: no-repeat;
      ${device.xxlarge} {
        padding:80px 0;
      }
      ${device.large} {
        padding:100px 0;
      }
      .section-title{
        margin:0;
        h2{
          border-color:#fff;
        }
      }
  `}
  .section-title {
    p {
      color: #fff;
    }
  }
`;
export const TrQualityCriteriaInner = styled.div``;

export const TrQualityCriteriaBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

export const TrQualityCriteriaBoxInner = styled.div`
  flex: 1 1 29%;
  margin: 10px;
  max-width: 400px;
  background: rgb(255 255 255 / 11%);
  padding: 15px;
  border-radius: 4px;
  border: 1px solid #8e7aa5;
  display: flex;
  align-items: center;
  position: relative;
  min-height: 200px;
  cursor: pointer;
  transition: ${themeGet("transition")};
  &:hover {
    box-shadow: 0 0 40px -16px rgb(255 255 255);
    transform: translateY(-3px);
  }
  @media ${device.medium} {
    flex: 1 1 47%;
  }
  @media ${device.small} {
    flex: 1 1 100%;
  }
`;
export const TrQualityCriteriaIconwrap = styled.div`
  min-width: 80px;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 25px;
`;
export const TrQualityCriteriaTextwrap = styled.div`
  border-left: 1px solid #8e7aa5;
  padding-left: 25px;
`;
export const TrQualityCriteriaList = styled.ul`
  margin: 20px 0 0;
  padding: 0;
`;
export const TrQualityCriteriaItem = styled.li`
  color: #ccb8fe;
  font-size: 15px;
  margin-bottom: 12px;
  line-height: 1;
  padding-left: 15px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 6px;
    height: 6px;
    background: #fff;
    border-radius: 50px;
    top: 5px;
    left: 0;
  }
`;
