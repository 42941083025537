import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import Image from "@ui/image";
import Button from "@ui/button";
import Text from "@ui/text";
import Heading from "@ui/heading";
import {
  IntroWrapper,
  LeftBox,
  RightBox,
  ContentWarp,
  Styledtitle,
  ButtonWrap,
} from "./style";

const IntroArea = ({ data, layout, ...props }) => {

  return (
    <IntroWrapper layout={layout} {...props} id={data.section}>
      <Container>
        <Row>
          <Col lg={12}>
            <SectionTitle
              subtitle={data.section_title?.subtitle}
              title={data.section_title?.title}
              description={data.section_title?.description}
            />
          </Col>
        </Row>
        <Row>
        <Col lg={6} >
            <LeftBox>
              <Image
                src={data.images[0]?.src}
                alt={data.images[0]?.alt || "Info"}
                title={data.images[0]?.alt || "Info"}
              />
            </LeftBox>
          </Col>
          <Col lg={6}>
            <LeftBox>
              {data &&
                data.items?.map((el, index) => (
                  <ContentWarp key={index}>
                    <Styledtitle dangerouslySetInnerHTML={{ __html: el.title }}></Styledtitle>
                    {el && el.description && <Text>{el.description}</Text>}
                    {data?.buttons?.map(({ id, path, content, ...rest }) => (
                      <Button className="btn-learn-more"  key={id} path={path} {...rest}>
                        {content}
                      </Button>
                    ))}
                  </ContentWarp>
                ))}
            </LeftBox>
          </Col>
        </Row>
      </Container>
    </IntroWrapper>
  );
};

IntroArea.propTypes = {
  layout: PropTypes.oneOf([1, 2]),
};
IntroArea.defaultProps = {
  layout: 1,
};
export default IntroArea;
